import React, { useState, useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import loadable from '@loadable/component';
import { StaticImage } from 'gatsby-plugin-image';

import HeaderComponent from '@components/molecules/header';
import Seo from '@components/molecules/seo';
import Icon from '@components/atoms/icon';
import Button from '@components/atoms/button';
import Divider from '@components/atoms/divider';
import MainTitle from '@components/molecules/main-title-card';
import EmailForm from '@components/atoms/email-form';
import FreeTrial from '@components/organisms/free-trial';
import IconCardList from '@components/organisms/icon-card-list';
import ProductCard from '@components/organisms/product-card';
import GrowthNumbers from '@components/organisms/growth-numbers';

import { apiUrl } from '@helpers';
import { container, formRotated } from '@styles/main.module.scss';
import '@styles/includes/slick-carousel.scss';
import {
  btnWrapper,
  pulledLeft,
  pulledRight,
} from '@components/molecules/carousel/carousel.module.scss';
import { scheduler, schedulerItem } from './homepage.module.scss';

import icon from '@images/reduce-wage-costs.png';
import icon2 from '@images/time-saving.png';
import icon3 from '@images/eliminate-no-shows.png';

const Modal = loadable(() => import('@components/molecules/modal'));
const FooterComponent = loadable(() => import('@components/molecules/footer'));

const SampleNextArrow = (props) => {
  const { onClick } = props;
  return (
    <button className={`${btnWrapper} ${pulledRight}`} onClick={onClick} type="button">
      <Icon iconClass="arrow-right" fSize={1.2} />
    </button>
  );
};

const SamplePrevArrow = (props) => {
  const { onClick } = props;
  return (
    <button className={`${btnWrapper} ${pulledLeft}`} onClick={onClick} type="button">
      <Icon iconClass="arrow-left" fSize={1.2} />
    </button>
  );
};

const Home = () => {
  const Intl = useIntl();
  // dialog config
  const [showDialog, setShowDialog] = useState(false);
  const [values, setValues] = useState(null);
  const [deletedInvite, setDeleted] = useState(false);
  const openModal = () => setShowDialog(true);
  const closeModal = () => {
    setShowDialog(false);
    setValues(null);
  };

  const [settings, setSettings] = useState({
    dots: false,
    infinite: false,
    centerMode: true,
    speed: 500,
    slidesToShow: 1,
    vertical: false,
    slidesToScroll: 1,
    centerPadding: '20px',
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  });

  const checkList = [
    { title: Intl.formatMessage({ id: 'pages.homepage.subscribeCheckItemZero' }), id: '1asdd1a' },
    { title: Intl.formatMessage({ id: 'pages.homepage.subscribeCheckItemOne' }), id: '1asdd1a' },
    { title: Intl.formatMessage({ id: 'pages.homepage.subscribeCheckItemTwo' }), id: '1asdd1a' },
    { title: Intl.formatMessage({ id: 'pages.homepage.subscribeCheckItemThree' }), id: '1asdd1a' },
    { title: Intl.formatMessage({ id: 'pages.homepage.subscribeCheckItemFour' }), id: '1asdd1a' },
  ];

  const toggleDeleteInvite = (data) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: data.email }),
    };
    fetch(`${apiUrl}/delete-invite`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        closeModal();
        setDeleted(true);
        setValues(data);
        setTimeout(() => openModal(), 2000);
      });
  };

  // This one has been updated
  const formSuccessState = (val) => {
    if (val?.action !== 'delete') {
      closeModal();
      if (val !== null) {
        setValues(val);
        setTimeout(() => {
          openModal();
        }, 500);
      }
    } else {
      toggleDeleteInvite(val);
    }
  };

  const firstList = [
    {
      title: Intl.formatMessage({ id: 'pages.productOverview.featureItemTitleOne' }),
      description: Intl.formatMessage({ id: 'pages.productOverview.featureItemDescOne' }),
      alt: Intl.formatMessage({ id: 'pages.productOverview.featureItemTitleOne' }),
      icon,
      imageWidth: 97.69,
      imageHeight: 72.75,
      imagePadding: '.8rem 3.1rem 1.5rem 3rem',
    },
    {
      title: Intl.formatMessage({ id: 'pages.productOverview.featureItemTitleTwo' }),
      description: Intl.formatMessage({ id: 'pages.productOverview.featureItemDescTwo' }),
      alt: Intl.formatMessage({ id: 'pages.productOverview.featureItemTitleTwo' }),
      icon: icon2,
      imageWidth: 84.73,
      imageHeight: 95.31,
      imagePadding: '2.3rem 2rem 2.2rem 2rem',
    },
    {
      title: Intl.formatMessage({ id: 'pages.productOverview.featureItemTitleThree' }),
      description: Intl.formatMessage({ id: 'pages.productOverview.featureItemDescThree' }),
      alt: Intl.formatMessage({ id: 'pages.productOverview.featureItemTitleThree' }),
      icon: icon3,
      imageWidth: 93,
      imageHeight: 93,
      imagePadding: '1.2rem 1.72rem 1.269rem 1.8rem',
    },
  ];

  const productList1 = [
    {
      title: Intl.formatMessage({ id: 'pages.productOverview.mainFeatureOneCheckItemOneTitle' }),
      description: Intl.formatMessage({
        id: 'pages.productOverview.mainFeatureOneCheckItemOneDesc',
      }),
    },
    {
      title: Intl.formatMessage({ id: 'pages.productOverview.mainFeatureOneCheckItemTwoTitle' }),
      description: Intl.formatMessage({
        id: 'pages.productOverview.mainFeatureOneCheckItemTwoDesc',
      }),
    },
  ];

  const imageLocaleOne = (loc) => {
    if (loc === 'en') {
      return 'img-one-locale-en';
    }
    if (loc === 'es') {
      return 'img-one-locale-es';
    }
    if (loc === 'fr') {
      return 'img-one-locale-fr';
    }
    if (loc === 'de') {
      return 'img-one-locale-de';
    }
  };

  const productList2 = [
    {
      title: Intl.formatMessage({ id: 'pages.productOverview.mainFeatureTwoCheckItemOneTitle' }),
      description: Intl.formatMessage({
        id: 'pages.productOverview.mainFeatureTwoCheckItemOneDesc',
      }),
    },
    {
      title: Intl.formatMessage({ id: 'pages.productOverview.mainFeatureTwoCheckItemTwoTitle' }),
      description: Intl.formatMessage({
        id: 'pages.productOverview.mainFeatureTwoCheckItemTwoDesc',
      }),
    },
  ];

  const productList3 = [
    {
      title: Intl.formatMessage({ id: 'pages.productOverview.mainFeatureThreeCheckItemOneTitle' }),
      description: Intl.formatMessage({
        id: 'pages.productOverview.mainFeatureThreeCheckItemOneDesc',
      }),
    },
    {
      title: Intl.formatMessage({ id: 'pages.productOverview.mainFeatureThreeCheckItemTwoTitle' }),
      description: Intl.formatMessage({
        id: 'pages.productOverview.mainFeatureThreeCheckItemTwoDesc',
      }),
    },
  ];

  const imageLocaleThree = (loc) => {
    if (loc === 'en') {
      return 'img-three-locale-en';
    }
    if (loc === 'es') {
      return 'img-three-locale-es';
    }
    if (loc === 'fr') {
      return 'img-three-locale-fr';
    }
    if (loc === 'de') {
      return 'img-three-locale-de';
    }
  };

  const imageLocaleTwo = (loc) => {
    if (loc === 'en') {
      return 'img-two-locale-en';
    }
    if (loc === 'es') {
      return 'img-two-locale-es';
    }
    if (loc === 'fr') {
      return 'img-two-locale-fr';
    }
    if (loc === 'de') {
      return 'img-two-locale-de';
    }
  };

  useEffect(() => {
    if (values) {
      setTimeout(() => {
        setShowDialog(true);
      }, 1500);
    }
  }, [values]);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setSettings({
        ...settings,
        slidesToShow: 1,
      });
    } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
      setSettings({
        ...settings,
        slidesToShow: 2,
        centerMode: false,
        centerPadding: '0',
      });
    } else {
      setSettings({
        ...settings,
        slidesToShow: 3,
        centerMode: false,
        centerPadding: '0',
      });
    }
  }, []);

  return (
    <div className={container}>
      <Seo
        noTemplate
        title={Intl.formatMessage({ id: 'pages.homepage.metaTitle' })}
        description={Intl.formatMessage({ id: 'pages.homepage.metaDescription' })}
      />
      <HeaderComponent />
      <Modal
        close={closeModal}
        showDialog={showDialog}
        hasValues={values}
        setFormValues={(formValues) => formSuccessState(formValues)}
      />
      <MainTitle
        title={Intl.formatMessage({ id: 'pages.homepage.title' })}
        subtitle={Intl.formatMessage({ id: 'pages.homepage.description' })}
      />
      <div className={formRotated}>
        <EmailForm
          deleteSucceded={deletedInvite}
          changeModal={(val) => formSuccessState(val)}
          placeholder={Intl.formatMessage({ id: 'pages.miscellaneous.typeYourEmail' })}
          checkItemOne={Intl.formatMessage({ id: 'pages.miscellaneous.noCreditCard' })}
          checkItemTwo={Intl.formatMessage({ id: 'pages.miscellaneous.14DaysTrial' })}
          checkItemThree={Intl.formatMessage({ id: 'pages.miscellaneous.cancelAnytime' })}
          style="homepage"
        />
        <Divider className="style1" />
        <div className={`${scheduler}`}>
          <div className={`${schedulerItem}`}>
            <StaticImage
              src="../images/scheduler@2x.png"
              width={1226}
              height={699}
              alt="Scheduler"
              quality={96}
            />
          </div>
          <div className={`${schedulerItem}`}>
            <h1>With Rosteroo, scheduling takes minutes; not hours!</h1>
            <Button btnText="get started now" btnStyle="teal" onBtnClick={() => openModal()} />
          </div>
        </div>
      </div>
      <Divider />
      <IconCardList cardList={firstList} style="smallTimesheetImages" />
      <Divider />
      <ProductCard
        productName={Intl.formatMessage({ id: 'header.menu.shiftSchedulingLabel' })}
        title={Intl.formatMessage({ id: 'pages.productOverview.mainFeatureTitleOne' })}
        description={Intl.formatMessage({ id: 'pages.productOverview.mainFeatureDescOne' })}
        category="Scheduling"
        list={productList1}
        image={imageLocaleOne(Intl.locale)}
        imagePadding="0rem"
        path="/product/scheduling"
        imageWidth={500}
        imageHeight={580}
        insideTitle
      />
      <Divider />
      <ProductCard
        productName={Intl.formatMessage({ id: 'header.menu.timesheetsLabel' })}
        title={Intl.formatMessage({ id: 'pages.productOverview.mainFeatureTitleTwo' })}
        description={Intl.formatMessage({ id: 'pages.productOverview.mainFeatureDescTwo' })}
        category="Timesheets"
        list={productList2}
        image={imageLocaleTwo(Intl.locale)}
        imagePadding="0rem 0rem 0rem 1.875rem"
        isSwapped
        path="/product/time-tracking"
        imageWidth={437.68}
        imageHeight={580}
        insideTitle
      />
      <Divider />
      <ProductCard
        productName={Intl.formatMessage({ id: 'header.menu.teamManagementLabel' })}
        title={Intl.formatMessage({ id: 'pages.productOverview.mainFeatureTitleThree' })}
        description={Intl.formatMessage({ id: 'pages.productOverview.mainFeatureDescThree' })}
        category="Team Management"
        list={productList3}
        image={imageLocaleThree(Intl.locale)}
        imagePadding="0rem 7rem"
        path="/product/team-management"
        imageWidth={350}
        imageHeight={580}
        insideTitle
      />
      <Divider />
      <GrowthNumbers title={Intl.formatMessage({ id: 'pages.miscellaneous.trustedGlobally' })} />
      <Divider />
      <FreeTrial
        title={Intl.formatMessage({ id: 'pages.homepage.subscribeTitle' })}
        description={Intl.formatMessage({ id: 'pages.homepage.subscribeDesc' })}
        list={checkList}
        onToggleModal={() => openModal()}
        onSuccessRes={(val) => formSuccessState(val)}
      />
      <FooterComponent />
    </div>
  );
};

export default Home;
