// extracted by mini-css-extract-plugin
export var btnDefault = "homepage-module--btn-default--Jv4VT";
export var attoiconJobs = "homepage-module--attoicon-jobs--9UO1R";
export var attoiconNotes = "homepage-module--attoicon-notes--j3f-F";
export var attoiconTimesheets = "homepage-module--attoicon-timesheets--DTOnO";
export var attoiconTimesheetsRounded1 = "homepage-module--attoicon-timesheets-rounded1--MY1xy";
export var attoiconMobileTt = "homepage-module--attoicon-mobile-tt--lFTa2";
export var attoiconNamedSites = "homepage-module--attoicon-named-sites--5bH1G";
export var attoiconProfitableBids = "homepage-module--attoicon-profitable-bids--XvNcv";
export var attoiconDepartments = "homepage-module--attoicon-departments--4M4gF";
export var attoiconTimeline = "homepage-module--attoicon-timeline--P+F1v";
export var attoiconFilters = "homepage-module--attoicon-filters--4OZwJ";
export var attoiconVerified = "homepage-module--attoicon-verified--SPBaE";
export var attoiconTimezone = "homepage-module--attoicon-timezone--+5ooS";
export var attoiconManualEntries = "homepage-module--attoicon-manual-entries--E96r4";
export var attoiconJobCosting = "homepage-module--attoicon-job-costing--j6mVa";
export var attoiconLocationTracking = "homepage-module--attoicon-location-tracking--AzZic";
export var attoiconBreak = "homepage-module--attoicon-break--L1RV4";
export var attoiconNightShift = "homepage-module--attoicon-night-shift--ZBWkw";
export var attoiconOvertime = "homepage-module--attoicon-overtime--9PdWO";
export var attoiconTimeTracking1 = "homepage-module--attoicon-time-tracking1--H6CH3";
export var attoiconTimeTracking = "homepage-module--attoicon-time-tracking--gQbnN";
export var attoiconLocationHistory = "homepage-module--attoicon-location-history--lU-k+";
export var attoiconPrivacy = "homepage-module--attoicon-privacy--vvSVv";
export var attoiconTimeEntryHistory = "homepage-module--attoicon-time-entry-history--659Ja";
export var attoiconCustomize = "homepage-module--attoicon-customize--Ot+lV";
export var attoiconRoundedClock = "homepage-module--attoicon-rounded-clock--zwBU5";
export var attoiconTimeOff = "homepage-module--attoicon-time-off--ekCgC";
export var attoiconNamedLocations = "homepage-module--attoicon-named-locations--COkLd";
export var attoiconSandClock = "homepage-module--attoicon-sand-clock--nRAYC";
export var attoiconBattery = "homepage-module--attoicon-battery--NOp37";
export var attoiconSupport = "homepage-module--attoicon-support--XHDn-";
export var attoiconHelpSupport = "homepage-module--attoicon-help-support--WDBLG";
export var attoiconWebTt = "homepage-module--attoicon-web-tt--S5Rmo";
export var attoiconArchive = "homepage-module--attoicon-archive--vbS6D";
export var attoiconEmail = "homepage-module--attoicon-email--eKung";
export var attoiconKiosk = "homepage-module--attoicon-kiosk--Z8-cn";
export var attoiconShare = "homepage-module--attoicon-share--HzVHk";
export var attoiconCrew = "homepage-module--attoicon-crew--MAkov";
export var attoiconTeamActivity = "homepage-module--attoicon-team-activity--Qa2jY";
export var attoiconTeam = "homepage-module--attoicon-team--IHIMN";
export var attoiconWages = "homepage-module--attoicon-wages--lMZc4";
export var attoiconNotification = "homepage-module--attoicon-notification--lTko1";
export var attoiconAvatar = "homepage-module--attoicon-avatar--MjC9V";
export var attoiconViewMap = "homepage-module--attoicon-view-map--yEYSf";
export var attoiconMovementTracking = "homepage-module--attoicon-movement-tracking--iKNw-";
export var attoiconWageEstimates = "homepage-module--attoicon-wage-estimates---Us8u";
export var attoiconWageEstimatesBold = "homepage-module--attoicon-wage-estimates-bold--mvYRx";
export var attoiconClose = "homepage-module--attoicon-close--bvQtX";
export var attoiconPlus = "homepage-module--attoicon-plus--qakBH";
export var attoiconMinus = "homepage-module--attoicon-minus--+N-K4";
export var attoiconTick = "homepage-module--attoicon-tick--WP8k2";
export var attoiconArrowLeft = "homepage-module--attoicon-arrow-left--S-DXv";
export var attoiconArrowRight = "homepage-module--attoicon-arrow-right--eKn9g";
export var attoiconArrowDown = "homepage-module--attoicon-arrow-down--NwlgS";
export var attoiconArrowUp = "homepage-module--attoicon-arrow-up--Z+tU-";
export var attoiconPlay = "homepage-module--attoicon-play--zVzt8";
export var attoiconLongArrow = "homepage-module--attoicon-long-arrow--cz7JL";
export var sliderWrapper = "homepage-module--sliderWrapper--mtfN3";
export var sliderText = "homepage-module--sliderText--w-IEH";
export var numbers = "homepage-module--numbers--Vu8aF";
export var mobileImageWrapper = "homepage-module--mobileImageWrapper--0dDKy";
export var mobileImage = "homepage-module--mobileImage--oBLeV";
export var desktopImage = "homepage-module--desktopImage--z3SvY";
export var scheduler = "homepage-module--scheduler--epkRb";
export var schedulerItem = "homepage-module--schedulerItem--AF78A";